import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'


const BoxAccount = ({ user }) => {
    return (
        <div style={{ display:'flex',flexDirection:'column'}}>
       {user.phoneNumber&& <div style={{borderWidth:1,borderColor:'#fff',borderStyle:'solid',borderRadius:10,margin:10,padding:15,backgroundColor:'rgba(255, 0, 0, 0.3)',shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
        elevation: 4}}>
          <div style={style.AccountInfo}>
            <div style={style.divAccount}>{user.fullName} - {user.phoneNumber}</div>
            {/* <div style={[style.divAccount]}>
              {FormatCurrency(walletInfo)} đ
            </div> */}
          </div>
          <div style={style.row}>
            <div style={{marginRight:90}}>
              <div style={style.Menudiv}>Số dư</div>
              <div style={style.divAccount}>{FormatCurrency(user.availableBalance)}đ</div>
            </div>
            <div>
              <div style={style.Menudiv}>Tiền thưởng</div>
              <div style={style.divAccount}>{FormatCurrency(user.winingBalance)}đ</div>
            </div>
          </div>
        </div>}
              <div style={style.rowMenus}>
                <div >
                  <a
                    style={style.MenuSection}
                    href="/recharge"
                  >
                    <img
                    src={require('../../assets/icons/recharge.png')}
                    style={style.img}
                    />
                  </a>
                  <div style={style.Menudiv}>Nạp tiền</div>
                </div>
                <div>
                  <a
                    style={style.MenuSection}
                    href='/withdrawal' 
                  >
                    <img
                    src={require('../../assets/icons/withdraw.png')}
                    style={style.img}
                    />
                  </a>
                  <div style={style.Menudiv}>Đổi thưởng</div>
                </div>
                <div>
                  <a
                    style={style.MenuSection}
                    href='/huong-dan' 
                  >
                    <img
                    src={require('../../assets/icons/guide.png')}
                    style={style.img}
                    />
                  </a>
                  <div style={style.Menudiv}>Hướng dẫn</div>
                </div>
                <div>
                  <a
                    style={style.MenuSection}
                    href='/payment-history'
                  >
                    <img
                    src={require('../../assets/icons/history.png')}
                    style={style.img}
                    />
                  </a>
                  <div style={style.Menudiv}>Lịch sử</div>
                </div>
              </div>
        </div>
      );
};
function FormatCurrency(money) {
    return (money * 1000).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
const style = {
    btn:{
      borderRadius:20,
      paddingVertical:5,
      borderWidth:1,
      borderColor:'#D6D6D6',
      width:100
    },
    
    img:{
      width:30,height:30
      
    },
    HomeContainer: {
      flex: 1,
      paddingHorizontal: 8,
      paddingBottom: 11,
    },
  
    HeaderExtend: {
      zIndex: -1,
      backgroundColor: '#E8002A',
      height: 60,
      left: 0,
      width: '100%',
      position: 'absolute',
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    row:{
      flexDirection:'row',
     // justifyContent: 'space-between',
      alignItems:'center',
      display:'flex'
    },
  
    AccountInfo: {
      //paddingHorizontal: 10,
      justifyContent: 'space-between',

      //paddingTop:5,
      flexDirection: 'row',
      paddingBottom: 10,
     // borderBottomWidth:1,
     // borderWidth: 1,
      //borderStyle: 'dashed',
     // borderColor: '#fff',
      borderBottomColor: '#979797',
    },
    divAccount: {
      fontSize: 19,
      color: '#fff',
      fontFamily:'Montserrat-SemiBold',
    },
    divWin: {
      fontSize: 15,
      color: 'orange',
      fontFamily:'Montserrat-SemiBold',
    },
    AccountNavigate: {
      paddingHorizontal: 8,
      paddingTop: 12,
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    MenuSection: {
      alignItems: 'center',
      justifyContent: 'center',
      width:46,height:46,
      backgroundColor:'#fff',
      borderRadius:23,
      alignSelf:'center',
      display:'flex',
      margin:'0 auto'
    },
    Menudiv: {
      paddingTop: 3,
      paddingBottom:5,
      fontFamily:'Montserrat-SemiBold',
      color:'#fff',
      fontSize:13,
      //divAlign:'center'
    },
    rowMenus:{
        display:'flex',
        marginHorizontal:10,justifyContent:'space-around',
        flexDirection:'row',
     // justifyContent: 'space-between',
      alignItems:'center'
    }
  }
export default BoxAccount;